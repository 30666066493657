import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Style.scss";
import { Col, Container, Row } from "react-bootstrap";
import policy from "../Icons/policy.svg";
import AnimatedSection from "../../../Defaults/Animations/AnimatedSection";

export class PolicyProcedures extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Growth Securities || Policy & Procedures</title>
        </Helmet>
        <div className="policy_procedure-container">
          <Container className="policy_procedures">
            <AnimatedSection>
              <h1 className="title">
                POLI<span>CIES</span> & PRO<span>CEDURES</span>
              </h1>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={8} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">Policy for Penny Stock</h2>
                  <p className="disc">
                    <span>We</span> define Penny stocks as those stocks where
                    the market price is below or close to par, with the company
                    financials being weak with indicators such as loss,
                    accumulated losses, low sales revenue, low or negative
                    networth, signs of inactivity in the company, which are
                    having very less value.
                  </p>
                  <p className="disc">
                    The stocks, which are appearing in the list of illiquid
                    securities issued by the Exchanges every month. These stocks
                    are generally considered to be highly speculative and high
                    risk because of their lack of liquidity, large bi-ask
                    spreads, small capitalization and limited following and
                    disclosure.
                  </p>
                  <p className="disc">
                    Depend on the market condition and RMS Policy of the company
                    RMS reserve the right to refuse to provide the limit in
                    Penny stocks and losses if any on account of such refusal
                    shall be borne by client only.
                  </p>
                </Col>
                <Col lg={4} md={12} sm={12} className="policy_procedures-img">
                  <img
                    className="procedure_img"
                    src={policy}
                    alt="Growth policy illustration"
                    width="100%"
                  />
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    Setting up client’s exposure limits
                  </h2>
                  <p className="disc">
                    The stock broker may from time to time impose and vary
                    limits on the orders that the client can place through the
                    stock broker’s trading system (including exposure limits,
                    turnover limits, limits as to the number, value and/or kind
                    of securities in respect of which orders can be placed
                    etc.). The client is aware and agrees that the stock broker
                    may need to vary or reduce the limits or impose new limits
                    urgently on the basis of the stock broker’s risk perception
                    and other factors considered relevant by the stock broker
                    including but not limited to limits on account of
                    exchange/SEBI directions/limits (such as broker level/market
                    level limits in security specific/volume specific exposures
                    etc.), and the stock broker may be unable to inform the
                    client of such variation reduction or imposition in advance.
                    The client agrees that the stock broker shall not be
                    responsible for such variation, reduction or imposition or
                    the client’s inability or route any order through the stock
                    broker’s trading system on account of any such variation,
                    reduction or imposition of limits. The Client further agrees
                    that the stock broker may at any time, at its sole
                    discretion and without prior notice, prohibit or restrict
                    the client’s ability to place orders or trade in securities
                    through the stock broker, or it may subject any order place
                    by the client to review before its entry into the trading
                    systems any my refuse to execute/allow execution of orders
                    due to but not limited to the reason of lack of
                    margin/securities or the order being outside the limits set
                    by stock broker/exchange/SEBI and any other reasons which
                    the stock broker may deem appropriate in the circumstances.
                    The client agrees that the losses, if any on account of such
                    refusal or due to delay caused by such review, shall be
                    borne exclusively by the client alone.
                  </p>
                  <p className="disc">
                    We have margin based RMS system,. Total deposits of the
                    clients are uploaded in the system and client may take
                    exposure on the basis of margin applicable for respective
                    security as per VAR based margining system of the stock
                    exchange and/or margin defined by RMS based on their risk
                    perception. Client may take benefit of “credit for sale”
                    i.e. benefit of share held as margin by selling the same by
                    selection Delivery option through order entry window on the
                    trading platform, the value of share sold will be added with
                    the value of deposit and on the basis of that client may
                    take fresh exposure.
                  </p>
                  <p className="disc">
                    In case of exposure take on the basis of shares margin the
                    payment is required to be made before the exchange pay in
                    date otherwise it will be liable to square off after the pay
                    in time or any time due to shortage of margin.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">Applicable Brokerage Rate :</h2>
                  <p className="disc">
                    Exclusive of stamp duty, service tax, STT (Securities
                    Transaction Tax) and any other statutory levies. Brokerage
                    will be charged within the limits prescribed by
                    SEBI/Exchange. It is hereby further, clarify that brokerage
                    on option contract shall be charged on the premium amount at
                    which the option contract was bought or sold, not on the
                    strike price of the option contract. Subject to revision at
                    our sole discretion and as informed by a circular sent by
                    ordinary post/courier services/email.
                  </p>
                  <p className="disc">
                    It would be the duty of client to note the said charges
                    regularly and periodically and shall not raise any dispute
                    or claim in respect to said charges at any later stage. SIL
                    further clarifies the above mentioned charges could very
                    from client to client at the sole discretion of SIL and No
                    client would have any right to compare or claim charges
                    charged from other client by SIL.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    Imposition of penalty / delayed payment charges
                  </h2>
                  <p className="disc">
                    Clients will be liable to pay late pay in/delayed payment
                    charges for not making payment of their payin/margin
                    obligation on time as per the exchange requirement/schedule
                    as the rte of 2% per month. Similarly the stock broker will
                    also be liable to pay delayed payment charges to the client
                    for not making payment of their obligation on time, as per
                    the exchange requirement/schedule at the rate of 2% p.m.,
                    except in the cases covered by the “Running Account
                    Authorization” give by the client to the stock broker.
                  </p>
                  <p className="disc">
                    The Client agrees that the stock broker may impose fines /
                    penalties for any orders / trades / deals / actions of the
                    client which are contrary to this agreement / rules /
                    regulations / bye laws of the exchange or any other law for
                    the time being in force, at such rates and in such form as
                    it my deem fit. Further where the stock broker has to pay
                    any fine or bear any punishment from any authority in
                    connection with / as a consequence of /in relation to any of
                    the orders / trades / deals actions of the client, the same
                    shall borne by the client.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    The right to sell client’s securities or close client’s
                    positions, without giving notice to the client, on account
                    of non payment of client’s due
                  </h2>
                  <p className="disc">
                    Without prejudice to the stock brokers other right
                    (Including the right to refer the matter to arbitration),
                    the stock broker shall be entitled to liquidate /close out
                    all or any of the clients position without giving notice to
                    the client for non payment of margins or other amounts
                    including the pay in obligation, outstanding debts etc and
                    adjust the proceeds of such liquidation/close out, if any,
                    against the clients liabilities / obligation.
                  </p>
                  <p className="disc">
                    The client shall ensure timely availability of
                    funds/securities in form and manner at designated time and
                    in designated bank and depository account(s), for meeting
                    his/her/its pay in obligation of funds and securities. Any
                    and all losses and financial charges on account of such
                    liquidations/closing out shall be charged to & born by the
                    client. In case of securities lying in margin account/client
                    beneficiary account and having corporate actions like Bonus,
                    Stock split, Right issue etc, for margin or other purpose
                    the benefit of shares due to received under Bonus, Stock
                    split, Right issue etc. will be given when the shares is
                    actually received in the stock broker designated demat
                    account.
                  </p>
                  <p className="disc">
                    In case the payment of the margin/securities is made by the
                    client through a bank instrument, the stock broker shall be
                    at liberty to give the benefit/credit for the same only on
                    the realization of the funds from the said bank instruments
                    etc, at the absolute discretion of the stock broker. Where
                    the margin/security is made available by way of securities
                    or any other property, stock broker is empowered to decline
                    its acceptance as margin/security &/or to accept it at such
                    reduced value as the stock broker may deem fit by applying
                    haircuts or by valuing it by marking it to market or by any
                    other method as the stock broker may deem fit in its
                    absolute discretion.
                  </p>
                  <p className="disc">
                    The stock broker has the right but not the obligation, to
                    cancel all pending orders and to sell/close/liquidate all
                    open positions/securities/shares at the pre-defined square
                    off time or when Mark to Market (M-T-M) percentage reaches
                    or crosses stipulated margin percentage, whichever is
                    earlier, The stock broker will have sole discretion to
                    decide referred stipulated margin percentage depending upon
                    the market condition. In the event of such square off, the
                    client agrees to bear all the losses based on actual
                    executed prices, the client shall also be solely liable for
                    all and any penalties and charges levied by the exchange(s).
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    Shortages in obligations arising out of internal netting of
                    traders
                  </h2>
                  <p className="disc">
                    Stock broker shall not be obliged to deliver and securities
                    or pay any money to the client unless and unit the same has
                    been received by the stock broker from the exchange, the
                    clearing corporation/clearing house or other company or
                    entity liable to make the payment and the client has
                    fulfilled his/her/its obligations first.
                  </p>
                  <p className="disc">
                    The policy any procedure for settlement of shortage in
                    obligations arising out of internal netting of the traders
                    is as under
                  </p>
                  <ul className="poli_pro-lists">
                    <li className="lists">
                      If securities cannot be purchased from market due to any
                      force majeure condition, the short delivering seller is
                      debited at the closing rate on T+2 day or Auction day on
                      Exchange +10% where the delivery is matched partially or
                      fully at the Exchange Clearing, the delivery and
                      debits/credits shall be as per Exchange Debits and
                      Credits.
                    </li>
                    <li className="lists">
                      In cases of securities having corporate actions all cases
                      of short delivery of cum transactions which cannot be
                      auctioned on cum basis or where the cum basis auctioned on
                      cum basis or where the cum basis auction payout is after
                      the book closure/record date, would be compulsory closed
                      out at higher of 10% above the official closing price from
                      first trading day of the settlement till the auction day.
                    </li>
                    <li className="lists">
                      The Short delivering client is debited by an amount
                      equivalent to 20% above of closing rate of day prior to
                      Payin/Payout Day. The securities delivered short are
                      purchased from market on T+2 day and the purchase
                      consideration (inclusive of all statutory taxes & levies)
                      is debited to the short delivering seller client along
                      with reversal entry of provisionally amount debited
                      earlier.
                    </li>
                  </ul>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    Conditions under which a client may not be allowed to take
                    further position or the broker may close the existing
                    position of a client
                  </h2>
                  <p className="disc">
                    We have margin based RMS system. Client may take exposure
                    upto the amount of margin available with us. Client may not
                    be allowed to take position in case of
                    non-availability/shortage of margin as per our RMS policy of
                    the company. The existing position of the client is also
                    liable to square off/close out without giving notice due to
                    shortage or margin/non making of payment for their paying
                    obligation/outstanding debts.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
            <AnimatedSection>
              <Row className="policy_procedures-rows">
                <Col lg={12} md={12} sm={12} className="policy_procedures-text">
                  <h2 className="subtitle">
                    Temporarily suspending or closing a client’s account at the
                    client’s request
                  </h2>
                  <p className="disc">
                    On the request of the client in writing, the client account
                    can be suspended temporarily and same can be activated on
                    the written request of the client only. During the period
                    client account is suspended, the market transaction in the
                    client account will be prohibited. However client
                    shares/ledger balance settlement can take place.
                  </p>
                  <p className="disc">
                    <b>Deregistering a client:-</b>
                    Not with standing anything to the contrary stated in the
                    agreement, the stock broker shall be entitled to terminate
                    the agreement with immediate effect in any of the following
                    circumstances:
                  </p>
                  <ul className="poli_pro-lists">
                    <li className="lists">
                      If the action of the client are prima facie
                      illegal/improper or such as to manipulate the prices of
                      any securities or disturb the normal/proper functioning of
                      securities or disturb the normal/proper functioning of the
                      marketing, either alone or in conjunction with others.
                    </li>
                    <li className="lists">
                      If there is any commencement of a legal process against
                      the client under any law in force;
                    </li>
                    <li className="lists">
                      On the death/lunacy or other disability of the Client;
                    </li>
                    <li className="lists">
                      If the client being a partnership firm, has any steps
                      taken by the Client and/or its partners for dissolution of
                      the partnership;
                    </li>
                    <li className="lists">
                      If the Client suffers any adverse material change in
                      his/her/its financial position or default in any other
                      agreement with the Stock broker;
                    </li>
                    <li className="lists">
                      If there is reasonable apprehension that the Client unable
                      to pay its debts or the Client has admitted its inability
                      to pay its debts, as they become payable;
                    </li>
                    <li className="lists">
                      If the Client is in breach of any term, condition or
                      covenant of this Agreement;
                    </li>
                    <li className="lists">
                      If the Client has made any material misrepresentation of
                      facts, including (without limitation) in relation to the
                      Security
                    </li>
                    <li className="lists">
                      If a receiver, administrator or liquidator has been
                      appointed or allowed to be appointed of all or any part of
                      the undertaking of the Client;
                    </li>
                    <li className="lists">
                      If the Client have taken or suffered to be taken any
                      action for its reorganization, liquidation or dissolution;
                    </li>
                    <li className="lists">
                      If the Client has voluntarily or compulsorily become the
                      subject of proceedings under any bankruptcy or insolvency
                      law or being a company, goes into liquidation or has a
                      receiver appointed in respect of its assets or refers
                      itself to the Board for Industrial and Financial
                      Reconstruction or under any other law providing protection
                      as a relief undertaking:
                    </li>
                    <li className="lists">
                      If any covenant or warranty of the Client is incorrect or
                      untrue in any material respect;
                    </li>
                  </ul>
                  <p className="disc">
                    <b>Inactive Client account: -</b>
                    Client account will be considered as inactive if the client
                    does not trade for period of one year. Calculation will be
                    done at the beginning of every month and those clients who
                    have not traded even a single time will be considered as
                    inactive, the shares/credit ledger balance if any will be
                    transferred to the client within one week of the identifying
                    the client as inactive. The client has to make written
                    request for reactivation of their account.
                  </p>
                  <p className="disc">
                    Trading in Exchange is in Electronic Mode, based on VSAT,
                    leased line, ISDN, Modern and VPN, combination of the
                    technologies and computer systems to place and route orders.
                    I/we understand that there exists a possibility of
                    communication failure or system problems or slow or delayed
                    response from system or trading halt or any break down in
                    our back office/front end system, or any such other
                    problems/glitch whereby not being able to establish access
                    to the trading system/network, which may be beyond your
                    control and may result in delay in processing or not
                    processing buy or sell Orders either in part or in full.
                    I/We shall be fully liable and responsible for any such
                    problem/fault.
                  </p>
                  <p className="disc">
                    <b>
                      Client Acceptance of Policies and Procedures stated here
                      in above:
                    </b>
                    I/We have fully understood the same and do hereby sign the
                    same and agree not to call into question the validity,
                    enforceability and applicability of any provision/clauses
                    this documents any circumstances what so ever. These
                    Policies and Procedures may be amended/changed unilaterally
                    by the broker, provided the change is informed to me/us with
                    through any one or more means or methods, I/we agree never
                    to challenge the same on any grounds including delayed
                    receipt/ non receipt or any other reasons whatsoever. These
                    Policies and Procedures shall always be read always be read
                    along with the agreement and shall be compulsorily referred
                    to while deciding any dispute/difference or claim between
                    me/us and stock broker before any court of
                    law/judicial/adjudicating authority including
                    arbitrator/mediator etc.
                  </p>
                </Col>
              </Row>
            </AnimatedSection>
          </Container>
        </div>
      </>
    );
  }
}

export default PolicyProcedures;
